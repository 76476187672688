import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

// Components
import HeroSpace from '../components/HeroSpace/HeroSpace';
import ContentWrapper from '../components/ContentWrapper/ContentWrapper';
// import ActionButton from '../components/ActionButton/ActionButton';

// Images
import bulletPointIcon from '../../assets/icons/bulletpoint.png';

import { txt, bg, flex, combine } from '../style/utils';
import { getImage } from '../utils/queryUtils';

import styles from './sectors.module.scss';

const Sectors = ({ data }) => {
  const SectorDetails = [
    {
      name: 'Internal Communications',
      image: {
        fluid: getImage(data.sectors, 'sector-internalcomms.jpg').fluid,
        alt: 'Corporate lobby with digital sign visible',
      },
      text: [
        'Digital internal communications enable you to communicate instantly with large groups of employees. You can keep them up to date on best practice, motivate your teams, or remind your sales teams to cross-sell. ',
        'Whatever your motivation, using a reactive digital signage system for your internal comms has some seriously indisputable benefits. ',
      ],
      benefits: [
        'Be reactive, or proactive with agile messaging. ',
        'Save time, money and effort designing, printing, pinning up and replacing. ',
        'Keep messaging on-brand every time, without using costly designers. ',
        'Share useful information with staff with <a href="/integrations/">real-time feeds</a> from 3rd party sites such as social media platforms, Yammer or Power BI.',
        'Reach many employees (or customers) at any one time.',
        'Communicate in a sleek and contemporary way - no more defaced and dog-eared posters promoting out-dated events. ',
        'Labour-saving messaging management with just one central solution. ',
      ],
      cta: {
        text:
          "See how Flare's helping Vodafone communicate with teams around the world",
        link: '/case-studies/vodafone',
      },
    },
    {
      name: 'Hospitality',
      image: {
        fluid: getImage(data.sectors, 'sector-hospitality.jpg').fluid,
        alt: 'Health spa reception with two prominent digital signs',
      },
      text: [
        'There are more opportunities when you can be reactive. Using Flare in a café, bar, hotel or restaurant setting can really help you to engage with your customers or staff on a whole new level, and boost your sales.',
        "Whether you want to smarten the appearance of your menus or signage, or whether the weather's warming up and a coffee cooler promo could bring in some extra sales, the ability to be reactive is where digital signage really makes a difference. But that's not all. Other benefits of Flare include:",
      ],
      benefits: [
        'Look professional with beautiful, branded communications with the use of our templates.',
        'Focus on hygiene as digital signage is a more hygienic approach to menu display. ',
        'Save time with quick and easy updates so you can change the menu or your promotions when ingredients run out. ',
        'Learn from your customers when you integrate feedback left on social media.',
        'Connect with your customers as you encourage online engagement in real time.',
      ],
      cta: null,
    },
    {
      name: 'Healthcare',
      image: {
        fluid: getImage(data.sectors, 'sector-healthcare.jpg').fluid,
        alt: 'Doctors surgery reception with large digital video wall',
      },
      text: [
        'In the healthcare industry, information is changing constantly. Keeping your communications relevant and fresh can be time-consuming. Digital signage is a great way to give your patients and staff up to date information. This can reduce time-consuming interruptions and help everything to run a little more smoothly. ',
        'Share news of the latest screening services you offer with a large number of patients. Ensure everyone is signposted to where they need to be. Whatever you need to say, digital signage with Flare provides a cost-effective and easy way to communicate. With Flare you can also:',
      ],
      benefits: [
        'Save time with quick and easy updates. No more printing, posting and replacing.',
        'Create a cleaner and tidier environment by making discarded posters and flyers a thing of the past.',
        'Amend messaging in moments to keep messages agile, relevant and up to date. ',
        'Share critical information by integrating feeds from third-party solutions, such as weather forecasts, pollen forecasts, current waiting times and more. ',
        'Maintain consistent and professional branding with branded templates.',
        'Save money as Flare works with your existing screens.',
      ],
      cta: null,
    },
  ];
  return (
    <div>
      <HeroSpace
        title="Sectors"
        subtitle="Helping leading organisations to communicate."
        image={{
          fluid: data.heroImage.fluid,
          alt: 'Line drawing of offices',
          className: styles.heroGraphic,
        }}
      />
      <ContentWrapper backgroundClasses={bg.white}>
        {SectorDetails.map((sector, i) => (
          <div
            key={i}
            className={combine(flex.col, i % 2 ? styles.sectorAlt : null)}
          >
            <h4
              className={combine(
                styles.sectorHeader,
                styles.sectorText,
                txt.purple
              )}
            >
              {sector.name}
            </h4>
            <div className={combine(flex.row, styles.sectorWrapper)}>
              <div className={combine(flex[1], styles.sectorText)}>
                {sector.text.map((paragraph, i) => (
                  <p key={i}>{paragraph}</p>
                ))}
                <ul>
                  {sector.benefits.map((benefit, i) => (
                    <li
                      dangerouslySetInnerHTML={{ __html: benefit }}
                      style={{ listStyleImage: `url(${bulletPointIcon}) ` }}
                      key={i}
                    />
                  ))}
                </ul>
                {sector.cta ? (
                  <p>
                    <Link
                      className={combine(txt.bold, styles.ctaLink, txt.purple)}
                      to={sector.cta.link}
                    >
                      {sector.cta.text}
                    </Link>
                  </p>
                ) : null}
                {/* <ActionButton variant="feature" className={styles.actionButton}>
                  See it in action
                </ActionButton> */}
              </div>
              <Img
                fluid={sector.image.fluid}
                alt={sector.image.alt}
                className={combine(styles.sectorImage, flex[1])}
              />
            </div>
          </div>
        ))}
      </ContentWrapper>
    </div>
  );
};

export default Sectors;

export const pageQuery = graphql`
  query sectorAssets {
    sectors: allImageSharp(
      filter: { fluid: { originalName: { regex: "/sector-.*[.]jpg/" } } }
    ) {
      edges {
        node {
          fluid(maxWidth: 1150, quality: 85) {
            name: originalName
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    heroImage: imageSharp(
      fluid: { originalName: { regex: "/hero-sectors[.]png/" } }
    ) {
      fluid(maxWidth: 1330, quality: 85, traceSVG: { color: "#ffffff" }) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }
`;
